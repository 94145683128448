<template>
  <v-snackbar v-model="status" top class="pt-0" color="orange" :timeout="10000">
    {{
      $tc("auth.passwordExpireNotificationMessage", passwordExpireNotification, {
        count: passwordExpireNotification
      })
    }}
    <v-btn class="pr-0" dark text @click="status = false">
      {{ $t("buttons.close") }}
    </v-btn>
  </v-snackbar>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    name: "PasswordExpireNotificationBar",
    data: () => ({
      status: false
    }),
    computed: {
      ...mapState({
        passwordExpireNotification: state => state.auth.passwordExpireNotification,
        passwordExpireNotificationStatus: state => state.auth.passwordExpireNotificationStatus
      })
    },
    watch: {
      passwordExpireNotificationStatus: {
        immediate: true,
        handler(val) {
          this.status = val;
        }
      }
    }
  };
</script>
